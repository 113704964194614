<template>
  <div class="inspection-trouble">
    <van-nav-bar
      left-arrow
      title="隐患上报"
      fixed
      @click-left="$router.back()"
    />
    <van-form
      :show-error-message="false"
      label-width="100px"
      class="van-form__text-color"
      @submit="onSubmit"
    >
      <van-field
        :rules="[{ required: true, message: '请填写异常分类' }]"
        :value="info.abnormalTypeName"
        clickable
        label="异常分类"
        name="abnormalType"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'abnormalType')"
      />
      <van-field
        v-model="info.name"
        :rules="[{ required: true, message: '请填写隐患名称' }]"
        label="隐患名称"
        name="name"
        placeholder="请输入内容"
        required
        maxlength="100"
        @input="handleInput('name')"
      />
      <van-field
        :rules="[{ required: true, message: '请选择所属部门' }]"
        :value="info.departmentName"
        clickable
        label="所属部门"
        name="department"
        placeholder="请选择"
        readonly
        required
        @click="departmentVisible = true"
      />
      <van-field
        v-model="info.poiName"
        :rules="[{ message: '请填写部位名称' }]"
        label="隐患部位"
        name="poiName"
        placeholder="请选择"
        readonly
        @click="facilityVisible = true"
      />
      <van-field
        :rules="[{ required: true, message: '请填写隐患级别' }]"
        :value="info.troubleLevelName"
        clickable
        label="隐患级别"
        name="troubleLevel"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'troubleLevel')"
      />
      <van-field
        :rules="[{ required: true, message: '请填写隐患类别' }]"
        :value="info.categoryName"
        clickable
        label="隐患类别"
        name="categoryId"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'categoryId')"
      />
      <van-field
        v-if="showContractor"
        :rules="[{ required: true, message: '请填写隐患名称' }]"
        :value="info.contractorName"
        clickable
        label="承包商"
        name="contractorId"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'contractorId')"
      />
      <van-field
        :value="info.planName"
        clickable
        label="所属计划"
        name="planId"
        placeholder="请选择"
        readonly
        @click="handlerTypesClick($event, 'planId')"
      />
      <van-field
        :rules="[{ required: true }]"
        label="发现方式"
        name="radio"
        required
      >
        <template #input>
          <van-radio-group v-model="info.discoveryId" direction="horizontal">
            <van-radio
              v-for="item in discoveryData"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
            <!-- :disabled="+item.value === 1" -->
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="info.description"
        :autosize="{ maxHeight: 200 }"
        label="备注"
        maxlength="500"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('description')"
      />
      <van-field
        v-model="info.gist"
        :autosize="{ maxHeight: 200 }"
        label="检查依据"
        maxlength="500"
        name="gist"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('gist')"
      />
      <van-field
        v-model="info.suggest"
        :autosize="{ maxHeight: 200 }"
        label="整改措施"
        maxlength="500"
        name="suggest"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('suggest')"
      />
      <van-field label="现场照片" name="uploader">
        <template #input>
          <image-uploader
            ref="image-uploader"
            v-model="info.fileList"
            :max-count="5"
            @change="onImageChange"
          />
        </template>
      </van-field>
      <div style="padding: 20px 16px">
        <van-button
          class="van-button van-button--commit-btn-bgc"
          native-type="submit"
          style="color: #fff"
          type="info"
          >提交</van-button
        >
      </div>
    </van-form>
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
    <cascade-department
      v-model="info.department"
      :visible="departmentVisible"
      disabled-key="--"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
    <add-facility
      v-model="facilityMap"
      :visible="facilityVisible"
      :department="info.department"
      activated="14"
      auth="org"
      title="隐患部位"
      @close="facilityVisible = false"
      @confirm="onFacilityConfirm"
    />
  </div>
</template>

<script>
import {
  getDepartmentByFacilityId,
  getSelectContractorsNames
} from "@/api/base";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";
import CascadeDepartment from "@/components/CascadeDepartment";
import SelectPopup from "@/components/SelectPopup";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import AddFacility from "@/components/AddFacility";
import { getSelectFindMode, getSelectPlans } from "@/api/psm/trouble";
import { filterEmoji, operateMessage, parseTime } from "@/utils";
import { getSelectErrorClass, toTrouble } from "@/api/psm/inspectionNew";
export default {
  name: "InspectionTrouble",
  components: { AddFacility, ImageUploader, SelectPopup, CascadeDepartment },
  mixins: [getSelectMixin],
  props: ["id"],
  data() {
    return {
      showContractor: false,
      popupVisible: false,
      facilityVisible: false,
      popupTitle: "",
      popupData: [],
      info: {
        // 任务关联ID
        taskRecordId: "",
        file: [],
        fileList: [],
        name: "",
        orgCode: "",
        orgName: "",
        troubleLevel: "",
        // 隐患类别
        categoryId: "",
        categoryName: "",

        contractorId: "",
        contractorName: "",
        description: "",
        // 所属排查计划
        planId: "",
        planName: "",
        // 所属部门
        department: "",
        departmentName: "",
        // 发现人部门，为登录用户
        discoverDeptId: "",
        // 发现人
        discoverer: "",
        // 是否已整改
        dispose: "0",
        // 隐患发现方式
        discoveryId: "101",
        abnormalType: "4",
        abnormalTypeName: "其他",
        discoverDate: "",
        // 检查依据
        gist: "",
        // 整改建议
        suggest: ""
      },
      discoveryData: [],
      planTypes: [],
      contractorTypes: [],
      errorTypes: [],
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      departmentVisible: false,
      popupTypesConf: {
        abnormalType: {
          title: "异常分类",
          keyName: "abnormalTypeName",
          typesKey: "errorTypes"
        },
        troubleLevel: {
          title: "隐患级别",
          keyName: "troubleLevelName",
          typesKey: "levelTypes"
        },
        categoryId: {
          title: "隐患类别",
          keyName: "categoryName",
          typesKey: "classesTypes"
        },
        contractorId: {
          title: "承包商",
          keyName: "contractorName",
          typesKey: "contractorTypes"
        },
        planId: {
          title: "所属计划",
          keyName: "planName",
          typesKey: "planTypes"
        }
      },
      facilityMap: {
        label: "",
        value: "",
        type: ""
      }
    };
  },
  created() {
    this.info.discoverer = this.userInfo.id;
    this.info.discoverDeptId = this.userInfo.department;
    this.info.discovererMobile = this.userInfo.mobile;
    this.info.patrolCheckResultId = this.id;
    this.info.name = this.$route.query.checkStandard;
    this.info.abnormalType = "4";
    this.info.discoveryId = "101";
    this.info.orgCode = this.userInfo.orgCode;
    this.info.department = this.userInfo.department;
    this.info.departmentName = this.userInfo.departmentName;
    this.initTypes();
    this.getSelectContractorsNames();
  },
  methods: {
    onImageChange(val) {
      this.info.fileList = val;
    },
    async getSelectContractorsNames() {
      try {
        const params = {
          orgCodeInList: this.info.orgCode
        };
        this.contractorTypes = await getSelectContractorsNames(params);
      } catch (error) {
        console.log(error);
      }
    },
    async initTypes() {
      try {
        const { orgCode } = this.userInfo;
        let params = { orgCode };
        params.status = 0;
        const rets = await Promise.all([
          getSelectPlans(params),
          getSelectFindMode(),
          getSelectErrorClass()
        ]);
        this.planTypes = rets[0];
        this.discoveryData = rets[1];
        this.errorTypes = rets[2];
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    handleInput(key) {
      this.info[key] = filterEmoji(this.info[key]);
    },
    async onFacilityConfirm(facility) {
      this.info.poiName = facility.label;
      this.info.poiType = facility.type;
      this.info.poiId = facility.value;
      if (!facility || !Object.keys(facility).length || !facility.value) {
        return false;
      }
      try {
        const ret = await getDepartmentByFacilityId(facility.value);
        this.info.department = ret.department;
        this.info.departmentName = ret.departmentName;
      } catch (e) {
        console.log("onFacilityChange -> e", e);
      }
    },
    onTreeConfirm({ label }) {
      this.info.departmentName = label;
      this.facilityMap.label = this.info.poiName = "";
      this.facilityMap.type = this.info.poiType = "";
      this.facilityMap.value = this.info.poiId = "";
    },
    handlerTypesClick(ev, key) {
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.info[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.info[this.popupType] = row.value;
      this.info[this.popupTypeName] = row.label;
      if (this.popupType === "categoryId") {
        if (row.contractorStatus === 1) {
          this.showContractor = true;
        } else {
          this.showContractor = false;
          // 清空关联的下级
          this.info.contractorId = "";
          this.info.contractorName = "";
        }
      }
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    processDefaultSaveInfo() {
      const { orgCode, id, department, mobile } = this.userInfo;
      this.info.file =
        this.info.fileList &&
        this.info.fileList.map(item => item && item.fileInfo);
      delete this.info.fileList;
      this.info.taskRecordId = -1;
      this.info.itemRecordsId = -1;
      this.info.checkItemId = -1;
      this.info.orgCode = orgCode;
      // 发现相关
      this.info.discoverer = id;
      this.info.discoverDeptId = department;
      this.info.discovererMobile = mobile;
      this.info.discoverDate = parseTime(Date.now(), "{y}-{m}-{d} {h}:{i}:{s}");
    },
    async onSubmit() {
      this.processDefaultSaveInfo();
      try {
        const r = await toTrouble(this.info);
        operateMessage(r, "提交隐患");
        if (r) {
          this.$router.back();
        }
      } catch (e) {
        console.log("onSubmit -> e", e);
        this.loading.status = false;
      }
    }
  }
};
</script>

<style lang="scss">
.inspection-trouble {
  padding-top: 62px;
  .van-button {
    width: 100%;
    height: 44px;
    border-radius: 27px;
  }
}
</style>
